// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

// JH
// Import variables
// Include any default variable overrides here !!! NOT after the BS import.
@import "./variables.scss";


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

@import "bootstrap/scss/bootstrap";

//
// Custom styles
//

//@import "icon-list";

// Global CSS
@import "global.scss";

// JH::Debug
@import "debug.scss";

// Components
@import "components/accordion";
@import "components/buttons";
@import "components/carousel";
@import "components/ccm-root";
@import "components/nav";

// Sections
@import "sections/masthead";
@import "sections/products";
@import "sections/contact";
